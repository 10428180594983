import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import { marked } from 'marked';
import { mangle } from "marked-mangle";
// import {markedHighlight} from "marked-highlight";
// import hljs from 'highlight.js';
import { v4 as uuidv4 } from 'uuid';
// import messagesReducer from '../redux/reducers/Chat.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom'
import { IconArrowDown } from '@tabler/icons-react';

import './Chat.css';


// require('dotenv').config();

const initialState = {
  messages: [],
  threadId: null
};

const TIME = 10
const MULTIPLIER =1

// const marked = new Marked(
//   markedHighlight({
//     langPrefix: 'hljs language-',
//     highlight(code, lang) {
//       const language = hljs.getLanguage(lang) ? lang : 'plaintext';
//       return hljs.highlight(code, { language }).value;
//     }
//   })
// );

marked.use(mangle());

const Sidebar = ({ }) => {

  const sidebarLinks = [
    {
      name: 'Terafac Live',
      href: 'https://terafac.live'
    },
    {
      name: 'JEX Portal',
      href: 'https://terafac.live'
    },
    {
      name: 'Virtual Teams',
      href: 'https://enterprise.terafac.live'
    }
  ]

  return (
    <div className="sidebar">
      <nav>
        <ul className="sidebarList">
          {sidebarLinks.map((link, index) => (
            <li key={index}>
              <div className="sidebarLink">
                <a href={link.href}>{link.name}</a>
              </div>
            </li>
          ))}

        </ul>
      </nav>
    </div>
  );
};




const Chat = ({ onUrlDetected, setUrl}) => {

  const host = process.env.REACT_APP_SOCKET_HOST || 'http://127.0.0.1:5000';

  const port = process.env.REACT_APP_SOCKET_PORT || '';

  const endpoint = port ? host+ ':' + port : host

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [response, setResponse] = useState('');
  const [threadId, setThreadId] = useState("");
  const [u, setU] = useState("")
  const [links, setLinks] = useState([])

  const lastInputTimeRef = useRef(new Date());

  const navigate = useNavigate();
  const location = useLocation();

  const chat = useSelector(state => state.chat);
  const dispatch = useDispatch();
  const socketRef = useRef();

  const [showScrollDown, setShowScrollDown] = useState(false);
  const chatContainerRef = useRef(null);

  function resizeIframe() {
    var iframe = document.getElementById("my-iframe");
    iframe.style.height = iframe.contentWindow.document.body.scrollHeight + "px";
  }

  const updateURLWithThread = (threadId) => {
    const queryParams = new URLSearchParams(location.search);

    if (threadId) {
      // Set or update the 'thread' query parameter
      queryParams.set('thread', threadId);
      // Update the URL without reloading the page
      navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
    }
    
  };

  function createThread() {
    const ti = uuidv4()
    setThreadId(ti);
    dispatch({ type: 'SET_THREAD_ID', payload: ti });
  }

  // Generate a unique thread ID when component mounts
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    
    console.log("THREAD ID", chat.threadId)
    const urlThreadId = queryParams.get('thread', chat.threadId);

    if (!urlThreadId) {
      createThread()
    } else {
      setThreadId(urlThreadId)
    }

    if (urlThreadId != threadId){
      updateURLWithThread(threadId)
    }
  }, []);

  // useEffect(() => {
  //   const checkScroll = () => {
  //     const isScrolledToBottom = chatContainerRef.current.scrollHeight - chatContainerRef.current.clientHeight <= chatContainerRef.current.scrollTop + 1;
  //     setShowScrollDown(!isScrolledToBottom);
  //   };

  //   const chatContainer = chatContainerRef.current;
  //   chatContainer.addEventListener('scroll', checkScroll);

  //   return () => {
  //     chatContainer.removeEventListener('scroll', checkScroll);
  //   };
  // }, [chat.messages]);

  useEffect(() => {
    if (isNearBottom()) {
      scrollToBottom();
    }

    const checkScroll = () => {
      setShowScrollDown(!isNearBottom());
    };

    const chatContainer = chatContainerRef.current;
    chatContainer.addEventListener('scroll', checkScroll);

    return () => {
      chatContainer.removeEventListener('scroll', checkScroll);
    };
  }, [chat.messages]);

  // Function to detect URL in message
  const detectURL = (message) => {
    if (message?.length) {
      const urlRegex = /https?:\/\/(?:www\.)?[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?/g;
      const regOutput = message.match(urlRegex)
      return regOutput;
    }
  };

  useEffect(() => {
    const url = detectURL(chat.messages[chat.messages.length - 1]);
        
    if (url) {
      setU(url[0])
      onUrlDetected(url[0]);
      setLinks([...links, { name: '', href: url[0] }])
    }
  }, [chat.messages])

  useEffect(() => {
    // console.log("host :", host, "port : ", port)
    socketRef.current = io(endpoint);

    // Emit 'join' event with threadId right after connection
    socketRef.current.on('connect', () => {
      updateURLWithThread(threadId)
      socketRef.current.emit('join', { threadId, name: 'Abavatar', length: chat.messages.filter((item) => item.substring(0, 3) !== "**Y").length });
    });

    socketRef.current.on('message', (messageObject) => {

      const message = messageObject['content']
      const role = messageObject['role']
      const start = messageObject?.start_of_message ?? false

      // setResponse((prevMessage) => {
      //   const url = detectURL(prevMessage + message);
      //   if (url) {
      //     setU(url[0])
      //     onUrlDetected(url[0]);
      //     setLinks([...links, { name: '', href: url[0] }])
      //   }

      //   if (role && message) {
      //     if (!start && prevMessage.substring(0, 3) != "**Y") { //&& ((prevMessage.length < role.length + 4) || prevMessage.substring(0, role.length + 4).includes(role))
      //       return prevMessage + message;
      //     } else {
      //       if (prevMessage !== "") {
      //         dispatch({ type: 'ADD_MESSAGE', payload: prevMessage });
      //       }
      //       return message
      //     }
      //   }
      //   lastInputTimeRef.current = new Date();

      // });

        if (role && message) {
          if (!start) { //&& ((prevMessage.length < role.length + 4) || prevMessage.substring(0, role.length + 4).includes(role))
            dispatch({ type: 'COMPLETE_MESSAGE', payload: message });
          } else {
            dispatch({ type: 'ADD_MESSAGE', payload: message });
          }
          lastInputTimeRef.current = new Date();
        }

    });

    const onTimerEvent = () => {
      const timeNow = new Date();
      const timeSinceLastInput = (timeNow - lastInputTimeRef.current) / 1000; // Time in seconds
      const threshold = Math.min(TIME * chat.messages.length, TIME * MULTIPLIER) // 15 seconds times number of messages

      if (timeSinceLastInput >= threshold) {
        // if (response) {
        //   dispatch({ type: 'ADD_MESSAGE', payload: response });
        // }
        // setResponse('')
        socketRef.current.emit('timer', { threadId , time: TIME, now: timeNow.toString()});
      }
    };

    // Set up the interval
    const intervalId = setInterval(onTimerEvent, TIME * 1000); // 15000 milliseconds = 15 seconds

    return () => {
      socketRef.current.disconnect();
      clearInterval(intervalId);
    };

  }, [threadId]);


  const sendMessage = () => {
    if (message === 'reset') {
      dispatch({ type: 'RESET' });
      // setResponse('')
      setMessage('');
      setUrl(null)
      createThread()
      window.history.pushState({}, '');
      // navigate('/', { replace: true });
    } else if (message) {
      // if (response) {
      //   dispatch({ type: 'ADD_MESSAGE', payload: response });
      // }

      // setResponse(`**You**: ${message}`)
      dispatch({ type: 'ADD_MESSAGE', payload: `**You**: ${message}`});
      socketRef.current.emit('message', { threadId, message });
      setMessage('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      lastInputTimeRef.current = new Date();
      sendMessage();
    }
  };

  const handleInput = (event) => {
    setMessage(event.target.value)
    lastInputTimeRef.current = new Date();
  }

  const scrollToBottom = () => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };

  const isNearBottom = () => {
    const threshold = 100; // Pixels from the bottom
    const position = chatContainerRef.current.scrollTop + chatContainerRef.current.clientHeight;
    const height = chatContainerRef.current.scrollHeight;
    return position + threshold >= height;
  };

  // return (
  //     <div style={{color:'white'}}>
  //       {JSON.stringify(chat)}
  //     </div>
  //   )

  return (
    <div className="chat-widget">
      <Sidebar sidebarLinks={links} />
      <div className="chat-container" ref={chatContainerRef}>
        {[...chat.messages, response ?? ''].map((m, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: marked.parse(m ?? '') }} />
        ))}
         {showScrollDown && (
          <button onClick={scrollToBottom} className="scroll-down-btn">
            <IconArrowDown />
          </button>
        )}
      </div>
      <div className="input-container">
        <input
          type="text"
          placeholder="Type your message..."
          value={message}
          onChange={handleInput}
          onKeyPress={handleKeyPress}
        />
        <button onClick={sendMessage}>
          <img src="https://www.thecoder.live/thecoder-logo-150.png" style={{ height: '30px' }} alt="send" />
        </button>
      </div>
    </div>
  );
};

export default Chat
