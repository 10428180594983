import React, { useEffect, useState, useRef, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import Chat from './components/Chat'
import './App.css';

const BackButton = ({ hide, setUrl, setHide }) => {
  const navigate = useNavigate();
  const mediaQuery = window.matchMedia('(max-width: 1024px)');

  return (
    <div className="button-container">
          {mediaQuery.matches && <button className="button" onClick={setHide}>{hide ? 'Browse': 'Chat'}</button> }
          { (!mediaQuery.matches || !hide) && <>
            <button className="button" onClick={() => navigate(-1)}>Back</button>
            <button className="button" onClick={() => navigate(1)}>Forward</button>
            <button className="button" onClick={() => setUrl('https://www.bing.com/search?q=Bing+AI&showconv=1&FORM=hpcodx')}>AI</button>
            <button className="button" onClick={() => setUrl('https://mentor.terafac.live')}>Mentor</button>
            </>
          }
    </div>
  );
};

const Iframe = ({ url, setUrl }) => {
  const [hide, setHide] = useState(true);

  return (
  <div className={hide ? "iframe-container backward" : "iframe-container forward"}>
  <BackButton url={url} setUrl={setUrl} hide={hide} setHide={() => setHide(!hide)}/>
    <iframe
        src={url || "https://mentor.terafac.live"} 
        title="Example Website"
      />
  </div>
    
  );
};

const App = () => {
  const [url, setUrl] = useState('');
  

  const handleUrlDetected = (detectedUrl) => {
    setUrl(detectedUrl);
  };

  return (
    <div className="App">
      <Chat 
        onUrlDetected={handleUrlDetected} 
        setUrl={setUrl}
      />
      <Iframe 
        url={url} 
        setUrl={setUrl}
      />
    </div>
  );
};

export default App;