const initialState = { 
  messages: [],
  threadId: null
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COMPLETE_MESSAGE':
      if (action.payload){
        const lastItemIndex = state.messages.length - 1;
        return { ...state, messages: [...state.messages.slice(0, lastItemIndex), state.messages[lastItemIndex] + action.payload] };  
      }

    case 'ADD_MESSAGE':
      if (action.payload && (state.messages[state.messages.length -1 ] !== action.payload)){
        return { ...state, messages: [...state.messages, action.payload] };  
      }

      return state;
    
    case 'UPDATE_MESSAGE':
      return {
        ...state,
        messages: state.messages.map((message, index) => {
          if (index === state.messages.length - 1) {
            return message + action.payload;
          }
          return message;
        }),
      };
    
    case 'SET_MESSAGES':
      return { ...state, messages: action.payload };

    case 'SET_THREAD_ID':
      return { ...state, threadId: action.payload };

    case 'RESET':
      return initialState

    default:
      return state;
  }
};

export default messagesReducer